<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        lg="6"
        md="9"
        sm="9"
      >
        <v-slide-y-transition appear>
          <v-card
            class="pa-3 pa-md-5 mx-auto"
            light
          >
            <pages-heading class="text-center display-3">
              Register
            </pages-heading>

            <v-row>
              <v-col
                cols="12"
                md="12"
                sm
              >
                <div class="text-center">
                  <div class="my-2" />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="firstName"
                        :rules="[rules.required]"
                        label="First Name"
                        maxlength="20"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="lastName"
                        :rules="[rules.required]"
                        label="Last Name"
                        maxlength="20"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="username"
                        label="Username"
                        :rules="[rules.required, rules.min, rules.max]"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        counter
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="password_verify"
                        block
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm Password"
                        counter
                        @click:append="show1 = !show1"
                      />
                    </v-col>

                    <v-col
                      class="d-flex"
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="statut"
                        :items="items"
                        label="I am a..."
                      />
                    </v-col>

                    <v-col
                      class="d-flex"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-if="statut === 'Student'"
                        v-model="description"
                        label="Group"
                        :rules="[rules.required]"
                        required
                      />
                      <v-text-field
                        v-if="statut === 'Professor'"
                        v-model="description"
                        label="Discipline"
                        :rules="[rules.required]"
                        required
                      />
                    </v-col>

                    <v-spacer />
                    <v-col
                      class="d-flex ml-auto xsm"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        x-large
                        block
                        :disabled="!valid"
                        color="success"
                        @click="validateRegister()"
                      >
                        Register
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import router from '@/router'
  const API_PATH = 'https://mps2-backend.herokuapp.com/api'

  export default {
    name: 'PagesRegister',

    components: {
      PagesHeading: () => import('./components/Heading'),
    },

    data: () => ({
      sections: [
        {
          icon: 'mdi-chart-timeline-variant',
          iconColor: 'primary',
          title: 'Marketing',
          text: 'We\'ve created the marketing campaign of the website. It was a very interesting collaboration.',
        },
        {
          icon: 'mdi-code-tags',
          iconColor: 'secondary',
          title: 'Fully Coded in HTML5',
          text: 'We\'ve developed the website with HTML5 and CSS3. The client has access to the code using GitHub.',
        },
        {
          icon: 'mdi-account-multiple',
          iconColor: 'cyan',
          title: 'Built Audience',
          text: 'There is also a Fully Customizable CMS Admin Dashboard for this product.',
        },
      ],
      socials: [
        {
          href: '#',
          icon: 'mdi-twitter',
          iconColor: '#1DA1F2',
        },
        {
          href: '#',
          icon: 'mdi-dribbble',
          iconColor: '#ea4c89',
        },
        {
          href: '#',
          icon: 'mdi-facebook',
          iconColor: '#3B5998',
        },

      ],
      statut: 'Student',
      status_error: false,
      error_message: '',
      status_success: false,
      success_message: '',
      dialog: true,
      tab: 0,
      tabs: [
        { name: 'Login', icon: 'mdi-account' },
        { name: 'Register', icon: 'mdi-account-outline' },
      ],
      valid: true,
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      password_verify: '',
      description: '',
      show1: false,
      items: ['Student', 'Professor', 'Admin'],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => (v && v.length >= 5) || 'Min 5 characters',
        max: (v) => (v && v.length <= 20) || 'Max 20 characters',
      },
    }),

    methods: {
      validateRegister () {
        axios
          .get(API_PATH + '/insertUser?username=' + this.username + '&password=' + this.password +
            '&perm=' + this.items.indexOf(this.statut) + '&firstName=' + this.firstName + '&lastName=' +
            this.lastName + '&description=' + this.description)
          .then((res) => {
            this.success_message = res.data.status
            if (this.success_message === 'succes') {
              this.status_success = true
              router.push('/pages/login')
            }
          })
          .catch((error) => {
            this.error_message = error.response.data.message
            this.status_error = true
          })
      },
    },
  }
</script>

<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial
</style>
